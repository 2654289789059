import PropTypes from "prop-types";
import React from "react";
import posed from 'react-pose';
import { graphql } from "gatsby";
import { ThemeContext } from "../layouts";
import Hero from "../components/Hero";
import Seo from "../components/Seo";

import IconOpquast from "!svg-react-loader!../images/svg-icons/opquast-issuer-FD62J8.svg";
import { ParallaxBanner } from 'react-scroll-parallax';

class IndexPage extends React.Component {
  separator = React.createRef();

  scrollToContent = e => {
    this.separator.current.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  render() {
    const {
      data: {
        posts: { edges: posts = [] },
        bgDesktop: {
          resize: { src: desktop }
        },
        bgTablet: {
          resize: { src: tablet }
        },
        bgMobile: {
          resize: { src: mobile }
        },
        site: {
          siteMetadata: { facebook }
        }
      }
    } = this.props;

    const backgrounds = {
      desktop,
      tablet,
      mobile
    };

    return (
      <React.Fragment>
        <ThemeContext.Consumer>
          {theme => (
            <Hero scrollToContent={this.scrollToContent} backgrounds={backgrounds} theme={theme} />
          )}
        </ThemeContext.Consumer>

        <hr ref={this.separator} />

        <div className="row">
          <div className="col"> 
            <h2>Expérience</h2>
            <p>Mon parcours dans la création et le développement web débute en 1997, passant de la multinationale à la startup, puis occupant différents postes au sein d'agences de communication. Au gré de ces expériences variées, de Paris à Angoulême jusqu'à mon Sud-Ouest natal, j'occupe diverses fonctions liées à la création visuelle, et gère également des missions complètes de conception, développement et intégration, ce qui me permet d'acquérir une vision et des compétences transversales.</p>
          </div>
          <div className="col">
            <h2>Polyvalence</h2>
            <p>Création graphique, développement, qualité rédactionnelle, veille technologique, bonnes pratiques de référencement, administration de serveur...la mise en oeuvre de plateformes de communication ou de services digitaux fait appel à des connaissances et des compétences très variées - et parfois complexes - que j'ai pris le soin d'acquérir, développer et maintenir à jour, toujours guidé par la même exigence de qualité.</p>
          </div>
          <div className="col">
            <h2>Valeurs</h2>
            <p>"Science sans conscience n'est que ruine de l'âme...". Au-delà de ces connaissances et compétences essentielles, je vous asure une relation de travail sincère, bienveillante, entièrement tournée vers la réussite de votre projet. Travailler ensemble, c'est faire le choix de la qualité mais aussi celui d'une certaine idée des rapports humains, basée sur l'écoute, l'empathie, la disponibilité et le respect. Et avec moi, ces bonnes intentions perdurent après la signature du bon de commande ! :)</p>
            </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="icon opquast">
              <a href="https://directory.opquast.com/fr/certificat/FD62J8/" target="_blank" title="Certificat OPQUAST"><IconOpquast /></a>
            </div>
          </div>
        </div>

        {/* 
        <ParallaxBanner
        className="your-class"
        layers={[
            {
                image: './static/photo-1507915600431-5292809c5ab7-40d4f4de1973c24726e4ef65ef5fd03e-fcb44.jpg',
                amount: 0.1,
            },
            {
                image: './static/photo-1507915600431-5292809c5ab7-40d4f4de1973c24726e4ef65ef5fd03e-fcb44.jpg',
                amount: 0.2,
            },
        ]}
        style={{
            height: '500px',
        }}
    >
    </ParallaxBanner>
      */}
        <style jsx>{`
          hr {
            margin: 0;
            border: 0;
          }

          .icon.opquast {
            margin: 0 auto;
            width:50%;
          }

          @from-width desktop {
            .icon.opquast {
              width:15%;
            }
          }

        `}</style>
      </React.Fragment>
    );
  }
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default IndexPage;

//eslint-disable-next-line no-undef
export const query = graphql`
  query IndexQuery {
    posts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//posts/[0-9]+.*--/" } }
      sort: { fields: [fields___prefix], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            prefix
          }
          frontmatter {
            title
            category
            author
            cover {
              children {
                ... on ImageSharp {
                  fluid(maxWidth: 800, maxHeight: 360) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
    bgDesktop: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 1200, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    bgTablet: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 800, height: 1100, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    bgMobile: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 450, height: 850, quality: 90, cropFocus: CENTER) {
        src
      }
    }
  }
`;

//hero-background
